import { useCallback, useEffect, useReducer, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button, Input } from '@pijma/crypto'
import { useBaseMask, usePhoneMask } from '@pijma/input'

import { sellApplicationClient } from '@api'
import { Dispatch, RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'
import {
  charIsRusLetterWithSpaces,
  nameSurnameRuRegexp,
  phoneRegexp,
  replaceNotRusSymbols,
} from '@utils'
import styled from 'styled-components'

import { nspkBanksService } from '../../../../../service/payform'
import {
  SESSION_PHONE_KEY,
  SESSION_RECEIVER_BANK_ID_KEY,
  SESSION_RECEIVER_KEY,
} from '../../../../../stores/payform/payForm'
import { TNspkBanksState } from '../../../../../stores/types/TNspkBanksState'
import InputValidation from '../../../../../validation/InputValidation'
import { H4 } from '../../../../common/header/Headings'
import { ModalSelectInput } from '../../../../common/input/modalSelect/ModalSelectInput'
import { Island } from '../../../../common/island'
import NavigationBar from '../../../../common/navigation/NavigationBar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px 24px;
`

const ModalSelectInputContainer = styled.div`
  width: 100%;
  padding: 0 20px 24px;
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px 20px;
`

const SourceTitle = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  width: 100%;
  padding: 20px 24px 16px;
`

const NavigationBarWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  width: 100%;
  align-items: start;
`

interface ReceiverBanksState {
  fetched: boolean
}

const receiverBanksReducer = (state: ReceiverBanksState) => {
  if (!state.fetched) {
    sellApplicationClient
      .getNspkBanks()
      .then((res) => nspkBanksService.init(res))
    return { fetched: true }
  }
  return state
}

interface StateProps {
  payForm: TPayFormState
  receiverBanks: TNspkBanksState
}

interface DispatchProps {
  setPhone: (phone: string) => void
  setReceiver: (receiver: string) => void
  setReceiverBank: (bankId: string) => void
  doPaymentBlocking: () => void
  resetPaymentBlocking: () => void
}

type Props = StateProps & DispatchProps

const SellSourceSbpPage = (props: Props) => {
  const redirectTo = useNavigate()
  const initialReceiverBanks = { fetched: false }
  // validation
  //// state
  const [phoneValidation, setPhoneValidation] = useState<InputValidation>({
    valid: true,
  })
  const [receiverValidation, setReceiverValidation] = useState<InputValidation>(
    {
      valid: true,
    },
  )
  const [formValid, setFormValid] = useState<boolean>(false)
  const [, receiverBanksDispatch] = useReducer(
    receiverBanksReducer,
    initialReceiverBanks,
  )

  //// effects
  const validatePhone = useCallback(
    (onlyValid = false) => {
      if (props.payForm.phone && phoneRegexp.test(props.payForm.phone)) {
        setPhoneValidation({ valid: true })
        return true
      }
      if (onlyValid) return false
      setPhoneValidation({
        valid: false,
        message: 'Введите 10 цифр',
      })
      return false
    },
    [props.payForm.phone],
  )
  const validateSender = useCallback(
    (onlyValid = false) => {
      if (
        props.payForm.receiver &&
        nameSurnameRuRegexp.test(props.payForm.receiver)
      ) {
        setReceiverValidation({ valid: true })
        return true
      }
      if (onlyValid) return false
      if (props.payForm.receiver == undefined) {
        setReceiverValidation({
          valid: false,
          message: 'Введите имя и фамилию',
        })
        return false
      }
      setReceiverValidation({
        valid: false,
        message: 'Это не похоже на имя и фамилию',
      })
      return false
    },
    [props.payForm.receiver],
  )

  useEffect(() => {
    if (props.payForm.phone) {
      validatePhone(true)
    } else {
      setFormValid(false)
    }
  }, [props.payForm.phone, validatePhone])

  useEffect(() => {
    if (props.payForm.receiver) {
      validateSender(true)
    } else {
      setFormValid(false)
    }
  }, [props.payForm.receiver, validateSender])

  useEffect(() => {
    setFormValid(
      props.payForm.receiver !== undefined &&
        props.payForm.phone !== undefined &&
        phoneValidation.valid &&
        receiverValidation.valid &&
        props.payForm.receiverBankId !== undefined,
    )
  }, [
    phoneValidation,
    receiverValidation,
    props.payForm.receiver,
    props.payForm.phone,
    props.payForm.receiverBankId,
  ])

  useEffect(() => {
    receiverBanksDispatch()
  }, [])

  // Pijma input masks
  const phoneMask = usePhoneMask({
    mask: {
      onBlur: () => {
        validatePhone()
      },
      value: props.payForm.phone,
      onValueChange: ({ formattedValue }) => {
        props.setPhone(formattedValue)
      },
    },
    country: 'RU',
  })
  const receiverMask = useBaseMask({
    type: 'text',
    format: replaceNotRusSymbols,
    isValidInputCharacter: charIsRusLetterWithSpaces,
    removeFormatting: replaceNotRusSymbols,
    value: props.payForm.receiver,
    onBlur: () => {
      validateSender()
    },
    onValueChange: ({ formattedValue }) => {
      props.setReceiver(formattedValue)
    },
  })

  const goToSumPage = () => {
    validatePhone()
    validateSender()
    sessionStorage.setItem(SESSION_PHONE_KEY, props.payForm.phone + '')
    sessionStorage.setItem(SESSION_RECEIVER_KEY, props.payForm.receiver + '')
    sessionStorage.setItem(
      SESSION_RECEIVER_BANK_ID_KEY,
      props.payForm.receiverBankId + '',
    )
    redirectTo(`../../sum`)
  }

  const onBankSelect = (bankId: string) => {
    props.setReceiverBank(bankId)
  }

  return (
    <Container>
      <NavigationBarWrapper>
        <NavigationBar
          paymentMethodRedirectLink={'../../source-select'}
          paymentDisabled
          sumDisabled
        />
      </NavigationBarWrapper>
      <Island size="M" bgColor="primary">
        <SourceTitle>
          <H4>В какой банк вы хотите получить&nbsp;перевод?</H4>
        </SourceTitle>
        <InputContainer>
          <Input
            {...phoneMask}
            label={`Номер телефона`}
            width={'100%'}
            disabled={props.payForm.paymentBlocking}
            type={'text'}
            placeholder={'+7 (000) 000-00-00'}
            errorMessage={phoneValidation.message}
            invalid={!phoneValidation.valid}
            allowClear={true}
            description={'Укажите тот, к которому привязана карта'}
          />
        </InputContainer>
        <InputContainer>
          <Input
            {...receiverMask}
            label={`Получатель`}
            width={'100%'}
            disabled={props.payForm.paymentBlocking}
            type={'text'}
            description={
              'Покупатель поймёт, кому он делает перевод, и отправит деньги быстрее'
            }
            placeholder={'Например, Антон Чехов'}
            errorMessage={receiverValidation.message}
            invalid={!receiverValidation.valid}
            allowClear={true}
          />
        </InputContainer>
        <ModalSelectInputContainer>
          {props.receiverBanks && (
            <ModalSelectInput
              defaultIcon={'/img/assets/otherBank.svg'}
              data={props.receiverBanks.banks}
              defaultText={'Банк получателя'}
              searchTitle={'Банк получателя'}
              searchPlaceholder={'Найти банк'}
              onSelect={onBankSelect}
              selected={props.payForm.receiverBankId}
            />
          )}
        </ModalSelectInputContainer>
        <ButtonContainer>
          <Button
            width={'100%'}
            size={'m'}
            disabled={!formValid}
            onPress={() => goToSumPage()}
          >
            К вводу суммы
          </Button>
        </ButtonContainer>
      </Island>
    </Container>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
  receiverBanks: state.nspkBanks,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setPhone: (phone: string) =>
      dispatch.payForm.set({
        phone,
      }),
    setReceiver: (receiver: string) =>
      dispatch.payForm.set({
        receiver,
      }),
    setReceiverBank: (bankId: string) => {
      dispatch.payForm.set({
        receiverBankId: bankId,
      })
    },
    doPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: true,
      }),
    resetPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: false,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SellSourceSbpPage)
