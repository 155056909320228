import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Spinner } from '@pijma/crypto'

import { topupApplicationClient } from '@api'
import { Dispatch, RootState } from '@stores'
import {
  ECurrency,
  EPaymentSource,
  toPaymentMethod,
  TPayFormState,
} from '@stores/types/*'
import { HttpStatusCode } from 'axios'
import styled from 'styled-components'

import { ETopupErrorCause } from '../../../../api/types/ETopupErrorCause'
import { EPaymentProvider } from '../../../../stores/types/EPaymentProvider'
import { H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: -80px;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
`

interface StateProps {
  payForm: TPayFormState
}

interface DispatchProps {
  doPaymentBlocking: () => void
  resetPaymentBlocking: () => void
}

type Props = StateProps & DispatchProps

const FindPurchaseOffersPage = (props: Props) => {
  const redirectTo = useNavigate()
  const applicationId = props.payForm?.application?.applicationUuid as string

  useEffect(
    () => {
      props.doPaymentBlocking()

      topupApplicationClient
        .topup(
          props.payForm?.application?.applicationUuid as string,
          toPaymentMethod(props.payForm.paymentSource as EPaymentSource),
          props.payForm?.amount as string,
          props.payForm?.application?.currency as ECurrency,
          props.payForm.sender as string,
          props.payForm.paymentProvider as EPaymentProvider,
          props.payForm.phone,
          props.payForm.last4pan,
        )
        .then((res) => {
          if (res.success) {
            window.location.replace(res.redirectUrl)
          }
        })
        .catch((reason) => {
          props.resetPaymentBlocking()
          if (
            reason.response.data.cause === ETopupErrorCause.NO_OFFERS_AVAILABLE
          ) {
            redirectTo(
              `../../application/${applicationId}/purchase/offers-not-found`,
            )
            return
          }
          console.log('something going wrong with payment', reason)
          if (reason.response.status === HttpStatusCode.Unauthorized) {
            redirectTo(`../../timeout`)
          } else {
            redirectTo(`../../failure`)
          }
        })
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <Spinner size={24} />

        <TitleWrapper>
          <H4>{'Ищем подходящее\nпредложение'}</H4>
        </TitleWrapper>
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    doPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: true,
      }),
    resetPaymentBlocking: () =>
      dispatch.payForm.set({
        paymentBlocking: false,
      }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FindPurchaseOffersPage)
